/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Metstrade 2023: An Unabashed Success"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "According to an article on Trade Only Today, Metstrade 2023 was an unabashed success. The trade show, which celebrated its 35th anniversary, drew record crowds and exhibitors to RAI Amsterdam. The event showcased recreational marine equipment and was held at the RAI Amsterdam exhibition center. The article highlights the success of the trade show and its importance in the industry."), "\n", React.createElement(_components.h2, null, "Key Highlights"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Metstrade 2023 was a highly successful trade show for the recreational marine equipment industry."), "\n", React.createElement(_components.li, null, "The event celebrated its 35th anniversary and drew record crowds and exhibitors to RAI Amsterdam."), "\n", React.createElement(_components.li, null, "It served as a global meeting place for professionals involved in the leisure marine industry."), "\n", React.createElement(_components.li, null, "The trade show showcased a wide range of products, materials, and systems related to the marine industry."), "\n"), "\n", React.createElement(_components.h2, null, "Conclusion"), "\n", React.createElement(_components.p, null, "Metstrade 2023 proved to be a significant event for the recreational marine equipment industry. With its record-breaking attendance and exhibitors, it solidified its position as a global meeting place for professionals in the leisure marine industry. The trade show's success highlights the importance of such events in promoting and advancing the marine industry."), "\n", React.createElement(_components.p, null, "Reference: ", React.createElement(_components.a, {
    href: "https://www.tradeonlytoday.com/post-type-feature/metstrade-2023-an-unabashed-success"
  }, "Metstrade 2023: An Unabashed Success - Trade Only Today")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Please note that the website requires a subscription to access the full article.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
